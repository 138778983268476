import React from "react"
import { css } from "@emotion/react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Article } from "../components/commons/styled-components"
import Container from "../components/commons/container"
import FeaturedImage from "../components/about/FeaturedImg"
import AboutContent from "../components/about/AboutContent"

const Sobre = ({ data }) => {
  const content = data.wpPage.about

  return (
    <Layout>
      <Article css={css`background-color: #D99F4F; margin-bottom: -50px;`}>
        <Container>
          <FeaturedImage image={content.imagemDestaque} />
          <AboutContent instagram={content.linkInstagram} lead={content.textoDestaque} content={content.texto} />
        </Container>
      </Article>
    </Layout>
  )
}

export default Sobre

export const query = graphql`
  query AboutQuery {
    wpPage(slug: {eq: "sobre"}) {
      about {
        imagemDestaque {
          altText
          srcSet
        }
        texto
        textoDestaque
        linkInstagram
      }
    }
  }
`
